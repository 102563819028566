import React from 'react'

import Terminal from '../components/terminal'
import TerminalItem from '../components/terminal-item'

const ChangelogPage = () => (
  <Terminal icon="comments" title="Changelog" command="Changelog">
    <TerminalItem>## 0.1.0 - 2018-09-15</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Changelog page.</TerminalItem>
    <TerminalItem>- Home page.</TerminalItem>
    <TerminalItem>- Layout component.</TerminalItem>
    <TerminalItem>- Bootstrap library.</TerminalItem>
    <TerminalItem>- Node package dependencies.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.1 - 2018-09-16</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- About page.</TerminalItem>
    <TerminalItem>- Navigation component.</TerminalItem>
    <TerminalItem>- Nav-item component</TerminalItem>
    <TerminalItem>- Sidebar component.</TerminalItem>
    <TerminalItem>- Hero component.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.2 - 2018-09-17</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Tailwind CSS Framework.</TerminalItem>
    <TerminalItem>### Removed</TerminalItem>
    <TerminalItem>- Bootstrap library.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.3 - 2018-09-18</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Avatar.</TerminalItem>
    <TerminalItem>- Footer component.</TerminalItem>
    <TerminalItem>- Font Awesome icons.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.4 - 2018-09-19</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Typeface Muli.</TerminalItem>
    <TerminalItem>- Typeface Ovo.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- &lt;h1&gt;, &lt;h2&gt; font.</TerminalItem>
    <TerminalItem>- &lt;body&gt; font.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.5 - 2018-09-20</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Contact page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.6 - 2018-09-21</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- 404 page.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Moved navigation component to main.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.7 - 2018-09-22</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- TerminalItem component.</TerminalItem>
    <TerminalItem>- Terminal component.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.8 - 2018-09-23</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Faux buttons to Terminal title bar.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Updated Terminal to accept parameters.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.9 - 2018-09-24</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Verbiage in Home page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.10 - 2018-09-25</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- TerminalItem line number computation.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Site color scheme.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.11 - 2018-09-26</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Projects page.</TerminalItem>
    <TerminalItem>- Link component.</TerminalItem>
    <TerminalItem>- Typeface Source Code Pro</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- &lt;section&gt; font.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.12 - 2018-09-27</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Verbiage in About page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.13 - 2018-09-28</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Favicon.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.14 - 2018-09-29</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Terminal date time dynamic computation.</TerminalItem>
    <TerminalItem>- TimeTerminalItem component.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.15 - 2018-09-30</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- TerminalItem number count padding.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.16 - 2018-10-01</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Contact page layout.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.17 - 2018-10-02</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Verbiage in About page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.18 - 2018-10-03</TerminalItem>
    <TerminalItem>### Removed</TerminalItem>
    <TerminalItem>- Under construction pages.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.19 - 2018-10-07</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Tap Carnival to Project page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.20 - 2018-10-08</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- reCAPTCHA to Contact page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.21 - 2018-10-09</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Affixed footer to bottom of mobile page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.22 - 2018-10-10</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Status badges to each project.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.23 - 2018-10-11</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Color coding support for badges.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.24 - 2018-12-04</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Disabled contact form.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.25 - 2019-02-15</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Photographs to About page.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Copyright year.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.26 - 2019-02-18</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Hero image to About page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.27 - 2019-03-01</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Analytics.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.28 - 2020-03-06</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Professional certifications.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Current employer.</TerminalItem>
    <TerminalItem>- Copyright year.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.29 - 2020-03-07</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Active state for navigation items.</TerminalItem>
    <TerminalItem>- Photographs to About page.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Verbiage in About page.</TerminalItem>
    <TerminalItem>### Removed</TerminalItem>
    <TerminalItem>- Project page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.30 - 2020-03-08</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Retrieve TimelineItem items via GraphQL.</TerminalItem>
    <TerminalItem>- Retrieve version number from config.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.31 - 2020-03-09</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Social media links to footer.</TerminalItem>
    <TerminalItem>- Navigation item labels.</TerminalItem>
    <TerminalItem>### Removed</TerminalItem>
    <TerminalItem>- CV link from Home page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.32 - 2020-03-10</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Reader view optimizations.</TerminalItem>
    <TerminalItem>- Address Lighthouse audit findings.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.33 - 2020-03-12</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Markdown support.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.34 - 2020-04-07</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Verbiage in About page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.35 - 2020-04-09</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Framework for posting dynamic content.</TerminalItem>
    <TerminalItem>- Blog page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.36 - 2020-04-17</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- CI/CD pipeline.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Migrate repo to GitLab.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.37 - 2020-04-18</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Reduce contact form width.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.38 - 2020-04-19</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Sharp image processing library.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.39 - 2020-04-20</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Increase sharp generated image quality.</TerminalItem>
    <TerminalItem>- Tweak card component text display.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.40 - 2020-04-21</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Left or right image in card component.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Cover photo in About page.</TerminalItem>
    <TerminalItem>- Verbiage in About page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.1.41 - 2020-04-26</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Rework Emotion to fix FOUC.</TerminalItem>
    <TerminalItem>- Upgrade node package dependencies.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.0 - 2020-04-27</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Preload fonts.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Update site color scheme.</TerminalItem>
    <TerminalItem>- Address accessibility issues.</TerminalItem>
    <TerminalItem>- Increment minor version.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.1 - 2020-05-01</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Favicon.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.2 - 2020-05-02</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- H1 component.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- PropType declared requirements.</TerminalItem>
    <TerminalItem>- Link component to accept children.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.3 - 2020-05-03</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Certifications component.</TerminalItem>
    <TerminalItem>- Instagram component.</TerminalItem>
    <TerminalItem>- Photos page.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Device breakpoints.</TerminalItem>
    <TerminalItem>- Promote About page to Home page.</TerminalItem>
    <TerminalItem>### Removed</TerminalItem>
    <TerminalItem>- About page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.4 - 2020-05-04</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Header component.</TerminalItem>
    <TerminalItem>- Tags in Blog page.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Blog page layout.</TerminalItem>
    <TerminalItem>- Link component.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.5 - 2020-05-05</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Time to read in Post page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.6 - 2020-05-06</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Enable contact form.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.7 - 2020-05-07</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Upgrade node package dependencies.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.8 - 2020-05-08</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Support for posting comments.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Increase line height.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.9 - 2020-05-09</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Per post comment control.</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Verbiage in Home page.</TerminalItem>
    <TerminalItem>### Removed</TerminalItem>
    <TerminalItem>- H1 component.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.10 - 2020-05-10</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Post page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.11 - 2020-05-11</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Link handling in Post page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.12 - 2020-05-12</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Address accessibility issues.</TerminalItem>
    <TerminalItem>- Disable comments on dev environments.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.13 - 2020-05-13</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Per post page title.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.14 - 2020-05-14</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Reset scroll position on page change.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.15 - 2020-05-15</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Table design in Post page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.16 - 2020-05-18</TerminalItem>
    <TerminalItem>### Removed</TerminalItem>
    <TerminalItem>- Instagram component.</TerminalItem>
    <TerminalItem>- Photos page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.17 - 2020-05-25</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Upgrade node package dependencies.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.18 - 2020-10-25</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Upgrade node package dependencies.</TerminalItem>
    <TerminalItem>### Removed</TerminalItem>
    <TerminalItem>- Link to Instagram account.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.19 - 2021-01-01</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Copyright year.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.20 - 2021-05-23</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Verbiage in Home page.</TerminalItem>
    <TerminalItem>- Upgrade node package dependencies.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.21 - 2021-08-21</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Job title.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.2.22 - 2021-11-01</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Update certification badges.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.0 - 2021-11-02</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Update browserslist.</TerminalItem>
    <TerminalItem>- Increment minor version.</TerminalItem>
    <TerminalItem>- Migrate repo to GitHub.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.1 - 2021-11-04</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- References from GitLab to GitHub.</TerminalItem>
    <TerminalItem>- Upgrade node package dependencies.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.2 - 2021-11-06</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Favicon.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.3 - 2021-11-10</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Link to Instagram account.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.4 - 2021-11-13</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Verbiage in Home page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.5 - 2021-12-12</TerminalItem>
    <TerminalItem>### Removed</TerminalItem>
    <TerminalItem>- Link to GitLab account.</TerminalItem>
    <TerminalItem>- Link to GitHub account.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.6 - 2022-03-27</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Contact form validation.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.7 - 2022-06-24</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Verbiage in Home page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.8 - 2022-06-25</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Photos page.</TerminalItem>
    <TerminalItem>### Removed</TerminalItem>
    <TerminalItem>- Link to Instagram account.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.9 - 2022-06-29</TerminalItem>
    <TerminalItem>### Added</TerminalItem>
    <TerminalItem>- Lightbox component.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.10 - 2022-07-07</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Image processing in Photos page.</TerminalItem>
    <TerminalItem />
    <TerminalItem>## 0.3.11 - 2022-09-11</TerminalItem>
    <TerminalItem>### Changed</TerminalItem>
    <TerminalItem>- Upgrade node package dependencies.</TerminalItem>
    <TerminalItem />
  </Terminal>
)

export default ChangelogPage
